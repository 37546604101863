/* ==========================================================================
   font awesome
   ========================================================================== */
.fa {
  padding: 0;
}

.fa:before {
  position: relative;
  margin: 0;
}

.fa-white::before {
  color: $white;
}

.fa-danger::before {
  color: $danger;
}

.chevron-right::before {
  font-size: 16px;
}

.fa-back {
  width: 24px;
  height: 24px;
  background: url('//widgets.risevision.com/apps/icons/glyphicons-back.png') center no-repeat;
}

.fa.fa-grip-large {
  position: absolute;
  left: -11px;
  top: 20px;
  color: $gray-300;
    &:before {
      content: "\f142 \202F \f142 \202F \f142 \202F \f142 \202F \f142 \202F \f142 \202F \f142 \202F \f142 \202F \f142";
    }
  }

  .fa-rotate-grip {
    @extend .fa-rotate-90;
    @media not all and (min-resolution:.001dpcm)
    {
        @supports (-webkit-appearance: none) {
            -webkit-transform: rotate(90deg) scaleX(2);
            left: -5px !important;
            top: 21px !important;
        }
    }
  }
