.schedule-fields-body {
  .scrollable-list {
    @media (min-width: $screen-md) {
      height: 275px;
      min-height: 275px;
      max-height: 275px;
    }

    @media (min-width: $screen-lg) {
      height: 100%;
      min-height: 387px;
      max-height: calc(100vh - 450px);
    }

    .draggable-mirror {
      border-top: 1px solid $gray-500;
      background: $pale-glow-850;
    }

    .list-group-item {
      min-height: 62px;
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .playlist-item-name {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: center;

      p {
        @extend .u_ellipsis-lg;
      }

      &.not-apple-tv-compatible p {
        max-width: 300px;
      }
    }
  }
}

.playlist-menu {
  margin-top: -1px;
}

.playlist-item-reference {
  @extend .u_ellipsis-md;
  max-width: 320px;
  line-height: 24px;
}

@media (min-width: $screen-sm) {
  .schedule-type {
    width: 450px;
  }
}

.schedule-type-label {
  width: 129px;
}

.schedule-type-value {
  max-width: 311px;
}