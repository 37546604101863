.storage-full {
  .table {
    .folder,
    .file {
      &:before {
        font-family: FontAwesome;
      }
    }
    .back-btn .folder {
      &:before {
        content: '\f07c';
        color: $rise-green;
      }
    }
    .sub-folder {
      margin-left: 22px;
      position: relative;
      &:after {
        content: '';
        width: 15px;
        height: 6px;
        display: block;
        position: absolute;
        top: 4px;
        left: -20px;
        border-bottom: $border;
        border-left: $border;
      }
    }
  }
  .notifications {
    margin: 0px 5px;
  }
  .toolbar {
    margin: 10px 0;
  }
  .rotating-text {
    color: $gray-600;
    margin-left: 5px;
  }

  .btn-toggle {
    width: 5rem;

    &.btn-default {
      &.active {
        background-color: $gray-200;
        color: $gray-900;
        border-color: $gray-400;
        border-width: 1px;
      }
    }
  }
}

.container.storage-full {
    .scrollable-list {
        padding: 4px;
        max-height: calc(100vh - 300px);
        width: 100%;
        @media (max-width: $screen-sm) {
            max-height: calc(100vh - 330px);
        }
    }
}

.modal-body.storage-full {
    .scrollable-list {
        padding: 4px;
        max-height: calc(100vh - 380px);
        width: 100%;
        @media (max-width: $screen-sm) {
            max-height: calc(100vh - 460px);
            min-height: 210px;
        }
    }
}

.storage-app .modal-body.storage-full {
    .scrollable-list {
        @media (max-width: $screen-sm) {
            max-height: calc(100vh - 290px);
        }
    }
}

.storage-app.storage-selector .modal-body.storage-full {
  .scrollable-list {
      @media (max-width: $screen-sm) {
          max-height: calc(100vh - 350px);
      }
  }
}

.storage-app.storage-selector #storageSelectorModal {
  height: 100vh;
  max-height: unset;
}

.file-empty-state path {
    fill: $gray-500;
    opacity: .65;
}

#store-modal-frame {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#storageSelectorModal {
  height: calc(100vh - 40px);
  max-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;

  .modal-header {
    flex: 0 0 auto;
  }
  .modal-body {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    // ng-include partials/storage/files-list.html
    > *:not(.flex-row):not(:first-child) {
      flex: 1 1 auto;
      margin: 20px auto 10px;
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: auto;
      border: 1px solid $gray-200;
      border-radius: 4px;

      > div {
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;
      }

      .scrollable-list {
        height: 100%;
        max-height: 100%;
        margin: 0 !important;

        &.border-container {
          border: none;
        }

        @media (max-height: 500px) {
          .grid-selector-list-item {
            height: 80px;
            width: 8%;
          }
        }
      }
    }
  }
  .modal-footer {
    flex: 0 0 auto;
  }
}

