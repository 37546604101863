/* ==========================================================================
   Glyphicons
   ========================================================================== */
.glyphicon-box {
  /* Display & Box Model */
  min-width: 60px;
  padding: 3px 10px;

  /* Visual */
  font-size: 23px;
}

.glyphicons {
  padding: 0;
}

.glyphicons:before {
  position: relative;
  margin: 0;
}

.glyphicons::before {
  color: $gray-700;
}

.glyphicon-danger::before {
  color: $danger;
}

.chevron-right::before {
  font-size: 16px;
}

.glyphicons-back {
  width: 24px;
  height: 24px;
  background: url('//widgets.risevision.com/apps/icons/glyphicons-back.png') center no-repeat;
}
